<template>
    <div>
        <slot/>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>